// Enums

export type CustomClaims = keyof typeof CustomClaims;
export const CustomClaims = {permissions: 'permissions', role: 'role'} as const;

export type CustomSessionClaims = keyof typeof CustomSessionClaims;
export const CustomSessionClaims = {
  business_unit_id: 'business_unit_id',
  business_unit_slug: 'business_unit_slug',
  customer_id: 'customer_id',
  environment_key: 'environment_key',
  embedded_data: 'embedded_data',
  session_identifier: 'session_identifier',
} as const;

export type Permissions = keyof typeof Permissions;
export const Permissions = {
  'Accounts:Manage': 'Accounts:Manage',
  'Accounts:Read': 'Accounts:Read',
  'Billing:Default': 'Billing:Default',
  'BusinessUnits:Manage': 'BusinessUnits:Manage',
  'BusinessUnits:Read': 'BusinessUnits:Read',
  'Payments:Default': 'Payments:Default',
  'Payments:Refund': 'Payments:Refund',
  'Payments:Void': 'Payments:Void',
  'Reports:Default': 'Reports:Default',
  'Transactions:Default': 'Transactions:Default',
  'Users:Manage': 'Users:Manage',
  'Users:Read': 'Users:Read',
  'AutopayEnrollment:Manage': 'AutopayEnrollment:Manage',
  'ScheduledPayments:Manage': 'ScheduledPayments:Manage',
} as const;

export type KnownCookies = keyof typeof KnownCookies;
export const KnownCookies = {PreferEmailLogin: 'PreferEmailLogin'} as const;

export type ClientQueryKeys = keyof typeof ClientQueryKeys;
export const ClientQueryKeys = {
  PaymentSuccessful: 'PaymentSuccessful',
  BusinessUnitSlug: 'BusinessUnitSlug',
  AuthSessionToken: 'AuthSessionToken',
  CustomRedirectUrl: 'CustomRedirectUrl',
  initialAction: 'initialAction',
  returnUrl: 'returnUrl',
  sessionIdentifier: 'sessionIdentifier',
  sessionAction: 'sessionAction',
  accountNumber: 'accountNumber',
  subaccountNumber: 'subaccountNumber',
  accountId: 'accountId',
} as const;

export type ClientActionQueryValues = keyof typeof ClientActionQueryValues;
export const ClientActionQueryValues = {
  'initiate-pos': 'initiate-pos',
  'AUTOPAY.ENROLL': 'AUTOPAY.ENROLL',
  'PAYMENT.INITIATE': 'PAYMENT.INITIATE',
  'ONBOARDING.RESUME': 'ONBOARDING.RESUME',
} as const;

export type Roles = keyof typeof Roles;
export const Roles = {
  'System User': 'System User',
  'Global Admin': 'Global Admin',
  'Organization Admin': 'Organization Admin',
  'Organization User': 'Organization User',
  Customer: 'Customer',
  QuickPay: 'QuickPay',
  Embedded: 'Embedded',
  'Base User': 'Base User',
} as const;

export type AccountCreateMethods = keyof typeof AccountCreateMethods;
export const AccountCreateMethods = {
  Manual: 'Manual',
  Imported: 'Imported',
  Api: 'Api',
  POS: 'POS',
} as const;

export type AccountFieldTypes = keyof typeof AccountFieldTypes;
export const AccountFieldTypes = {
  AccountNumber: 'AccountNumber',
  SubAccountNumber: 'SubAccountNumber',
  Name: 'Name',
  Zip: 'Zip',
  Balance: 'Balance',
  LateFee: 'LateFee',
  Description: 'Description',
  IssueDate: 'IssueDate',
  DueDate: 'DueDate',
  PhoneNumber: 'PhoneNumber',
  EmailAddress: 'EmailAddress',
  Note: 'Note',
  Address: 'Address',
  PastDueAmountMinor: 'PastDueAmountMinor',
} as const;

export type AccountFieldTypesThatCannotBeLookup = keyof typeof AccountFieldTypesThatCannotBeLookup;
export const AccountFieldTypesThatCannotBeLookup = {
  Balance: 'Balance',
  IssueDate: 'IssueDate',
  DueDate: 'DueDate',
  Note: 'Note',
  Address: 'Address',
} as const;

export type AccountFieldTypesThatCannotBeRequired =
  keyof typeof AccountFieldTypesThatCannotBeRequired;
export const AccountFieldTypesThatCannotBeRequired = {Address: 'Address'} as const;

export type AccountLogTypes = keyof typeof AccountLogTypes;
export const AccountLogTypes = {
  'Account History Begin': 'Account History Begin',
  'Account Manual Update': 'Account Manual Update',
  'Account Sync': 'Account Sync',
  'Account Sync With Balance Update': 'Account Sync With Balance Update',
  'Account Archived': 'Account Archived',
} as const;

export type PaperlessBillingActivityTypes = keyof typeof PaperlessBillingActivityTypes;
export const PaperlessBillingActivityTypes = {
  Enroll: 'Enroll',
  Unenroll: 'Unenroll',
  Update: 'Update',
} as const;

export type PaperlessBillingExportTypes = keyof typeof PaperlessBillingExportTypes;
export const PaperlessBillingExportTypes = {Csv: 'Csv'} as const;

export type BillingCycleStatuses = keyof typeof BillingCycleStatuses;
export const BillingCycleStatuses = {
  Processing: 'Processing',
  Draft: 'Draft',
  Approved: 'Approved',
  Error: 'Error',
  'Requires Bills': 'Requires Bills',
} as const;

export type NotificationStrategyTypes = keyof typeof NotificationStrategyTypes;
export const NotificationStrategyTypes = {
  None: 'None',
  'Per Billing Cycle': 'Per Billing Cycle',
  'Per Bill': 'Per Bill',
} as const;

export type BillParsers = keyof typeof BillParsers;
export const BillParsers = {
  StJohn: 'StJohn',
  CUSI: 'CUSI',
  UDS: 'UDS',
  RVS: 'RVS',
  Omnis: 'Omnis',
  'Ohio Software': 'Ohio Software',
  SaltCreek: 'SaltCreek',
  Bai: 'Bai',
} as const;

export type BillTemplates = keyof typeof BillTemplates;
export const BillTemplates = {generic: 'generic', 'generic-color': 'generic-color'} as const;

export type ReadingsSyncLogTypes = keyof typeof ReadingsSyncLogTypes;
export const ReadingsSyncLogTypes = {
  'Readings File Uploaded': 'Readings File Uploaded',
  'Readings File Processed': 'Readings File Processed',
  'Readings Linked': 'Readings Linked',
  'Readings Linking Error': 'Readings Linking Error',
  'Readings File Processing Error': 'Readings File Processing Error',
} as const;

export type ReadingIntervals = keyof typeof ReadingIntervals;
export const ReadingIntervals = {Daily: 'Daily', Monthly: 'Monthly'} as const;

export type AdjustmentTypes = keyof typeof AdjustmentTypes;
export const AdjustmentTypes = {Chargeback: 'Chargeback', 'ACH Return': 'ACH Return'} as const;

export type PaymentStatuses = keyof typeof PaymentStatuses;
export const PaymentStatuses = {
  Pending: 'Pending',
  Succeeded: 'Succeeded',
  Declined: 'Declined',
  Error: 'Error',
  Abandoned: 'Abandoned',
  Refunded: 'Refunded',
  Voided: 'Voided',
} as const;

export type PaymentSessionStatuses = keyof typeof PaymentSessionStatuses;
export const PaymentSessionStatuses = {
  RequiresPaymentFields: 'RequiresPaymentFields',
  RequiresPaymentSource: 'RequiresPaymentSource',
  RequiresCharges: 'RequiresCharges',
  RequiresConfirmation: 'RequiresConfirmation',
  Processing: 'Processing',
  RequiresGatewayResolution: 'RequiresGatewayResolution',
  RequiresFormResponse: 'RequiresFormResponse',
  Complete: 'Complete',
  Abandoned: 'Abandoned',
} as const;

export type PaymentIntentConfigScopes = keyof typeof PaymentIntentConfigScopes;
export const PaymentIntentConfigScopes = {
  Default: 'Default',
  Checkout: 'Checkout',
  Embedded: 'Embedded',
} as const;

export type PaymentTypes = keyof typeof PaymentTypes;
export const PaymentTypes = {'Base Payment': 'Base Payment', 'Service Fee': 'Service Fee'} as const;

export type PaymentFieldTypes = keyof typeof PaymentFieldTypes;
export const PaymentFieldTypes = {Text: 'Text'} as const;

export type PaymentLineItemTypes = keyof typeof PaymentLineItemTypes;
export const PaymentLineItemTypes = {
  AccountBalance: 'AccountBalance',
  OpenCharge: 'OpenCharge',
  LateFee: 'LateFee',
  Product: 'Product',
  ServiceFee: 'ServiceFee',
} as const;

export type PaymentMetaKeys = keyof typeof PaymentMetaKeys;
export const PaymentMetaKeys = {__Accounts: '__Accounts', __LineItems: '__LineItems'} as const;

export type PaymentChannels = keyof typeof PaymentChannels;
export const PaymentChannels = {
  QuickPay: 'QuickPay',
  IVR: 'IVR',
  Autopay: 'Autopay',
  POS: 'POS',
} as const;

export type PaymentEnabledModes = keyof typeof PaymentEnabledModes;
export const PaymentEnabledModes = {
  Enabled: 'Enabled',
  'Prevent New': 'Prevent New',
  Disabled: 'Disabled',
} as const;

export type TransactionExportTypes = keyof typeof TransactionExportTypes;
export const TransactionExportTypes = {
  'Appalachian Software': 'Appalachian Software',
  BAI: 'BAI',
  'Black Mountain': 'Black Mountain',
  'Bossier Sheriff': 'Bossier Sheriff',
  'Central Square': 'Central Square',
  'City of Slidell': 'City of Slidell',
  CSDC: 'CSDC',
  CUSI: 'CUSI',
  'Dynamic CSV': 'Dynamic CSV',
  'Frey Municipal Software': 'Frey Municipal Software',
  Generic: 'Generic',
  GFC: 'GFC',
  Incode: 'Incode',
  'iNHANCE Small Business': 'iNHANCE Small Business',
  JsReport: 'JsReport',
  Lamar: 'Lamar',
  'LMS Waterworks': 'LMS Waterworks',
  Munis: 'Munis',
  'Nicholson Business Systems': 'Nicholson Business Systems',
  'New World': 'New World',
  'Ohio Software': 'Ohio Software',
  OMNIS: 'OMNIS',
  'River Road CUSI': 'River Road CUSI',
  RVS: 'RVS',
  SaltCreek: 'SaltCreek',
  Softel: 'Softel',
  SoftWater: 'SoftWater',
  StJohn: 'StJohn',
  Thoroughbred: 'Thoroughbred',
  'Tyler Tech': 'Tyler Tech',
  'Tyler Tech (No Hyphens)': 'Tyler Tech (No Hyphens)',
  'West Baton Rouge Parish': 'West Baton Rouge Parish',
} as const;

export type TransactionStatuses = keyof typeof TransactionStatuses;
export const TransactionStatuses = {
  Approved: 'Approved',
  Declined: 'Declined',
  Error: 'Error',
} as const;

export type TransactionTypes = keyof typeof TransactionTypes;
export const TransactionTypes = {Sale: 'Sale', Void: 'Void', Refund: 'Refund'} as const;

export type CardBrands = keyof typeof CardBrands;
export const CardBrands = {
  Visa: 'Visa',
  Mastercard: 'Mastercard',
  Amex: 'Amex',
  Discover: 'Discover',
  Unknown: 'Unknown',
} as const;

export type ShortCardBrands = keyof typeof ShortCardBrands;
export const ShortCardBrands = {
  VISA: 'VISA',
  MC: 'MC',
  AMEX: 'AMEX',
  DISC: 'DISC',
  CHK: 'CHK',
  SAV: 'SAV',
  UNKN: 'UNKN',
} as const;

export type CardSwipeDevices = keyof typeof CardSwipeDevices;
export const CardSwipeDevices = {None: 'None', 'Encrypted Reader': 'Encrypted Reader'} as const;

export type CardEntryMethods = keyof typeof CardEntryMethods;
export const CardEntryMethods = {
  Unknown: 'Unknown',
  Keyed: 'Keyed',
  Swiped: 'Swiped',
  'EMV Contact': 'EMV Contact',
} as const;

export type DeviceManufacturers = keyof typeof DeviceManufacturers;
export const DeviceManufacturers = {
  Magtek: 'Magtek',
  Clover: 'Clover',
  'ID Tech': 'ID Tech',
  Ingenico: 'Ingenico',
  Verifone: 'Verifone',
  BBPOS: 'BBPOS',
} as const;

export type PlaidEnabledModes = keyof typeof PlaidEnabledModes;
export const PlaidEnabledModes = {Enabled: 'Enabled', Disabled: 'Disabled'} as const;

export type PaymentSourceTypes = keyof typeof PaymentSourceTypes;
export const PaymentSourceTypes = {'Credit Card': 'Credit Card', ACH: 'ACH'} as const;

export type PaymentSourceVerificationMethods = keyof typeof PaymentSourceVerificationMethods;
export const PaymentSourceVerificationMethods = {Plaid: 'Plaid'} as const;

export type TokenizationTypes = keyof typeof TokenizationTypes;
export const TokenizationTypes = {
  'msr.unencrypted': 'msr.unencrypted',
  'msr.encrypted': 'msr.encrypted',
  'emv.encrypted': 'emv.encrypted',
} as const;

export type PaymentGatewayTypes = keyof typeof PaymentGatewayTypes;
export const PaymentGatewayTypes = {
  'Paystar NACHA Gateway': 'Paystar NACHA Gateway',
  NMI: 'NMI',
  SPS: 'SPS',
  'Card Connect': 'Card Connect',
} as const;

export type SettlementBatchStatuses = keyof typeof SettlementBatchStatuses;
export const SettlementBatchStatuses = {Open: 'Open', Closed: 'Closed', Funded: 'Funded'} as const;

export type AutopayActivityTypes = keyof typeof AutopayActivityTypes;
export const AutopayActivityTypes = {
  Enroll: 'Enroll',
  UpdateSettings: 'UpdateSettings',
  Unenroll: 'Unenroll',
  AdminCancel: 'AdminCancel',
  SystemCancel: 'SystemCancel',
} as const;

export type AutopayExportTypes = keyof typeof AutopayExportTypes;
export const AutopayExportTypes = {Csv: 'Csv', Excel: 'Excel'} as const;

export type AutopayEnrollmentTypes = keyof typeof AutopayEnrollmentTypes;
export const AutopayEnrollmentTypes = {Organization: 'Organization', Customer: 'Customer'} as const;

export type AutopaySchedulingTypes = keyof typeof AutopaySchedulingTypes;
export const AutopaySchedulingTypes = {
  DueDateLeadTime: 'DueDateLeadTime',
  DayOfMonth: 'DayOfMonth',
} as const;

export type AbaNumberValidationModes = keyof typeof AbaNumberValidationModes;
export const AbaNumberValidationModes = {Enabled: 'Enabled', Disabled: 'Disabled'} as const;

export type AutopayEnabledModes = keyof typeof AutopayEnabledModes;
export const AutopayEnabledModes = {Enabled: 'Enabled', Disabled: 'Disabled'} as const;

export type ScheduledPaymentTypes = keyof typeof ScheduledPaymentTypes;
export const ScheduledPaymentTypes = {
  Autopay: 'Autopay',
  'One Time Payment': 'One Time Payment',
} as const;

export type ScheduledPaymentStatuses = keyof typeof ScheduledPaymentStatuses;
export const ScheduledPaymentStatuses = {
  Staged: 'Staged',
  Scheduled: 'Scheduled',
  Processing: 'Processing',
  Processed: 'Processed',
  Canceled: 'Canceled',
  Skipped: 'Skipped',
  Invalid: 'Invalid',
  InvalidPaymentSource: 'InvalidPaymentSource',
  InvalidProcessingDate: 'InvalidProcessingDate',
  InvalidEntityState: 'InvalidEntityState',
  InvalidIdempotencyKey: 'InvalidIdempotencyKey',
} as const;

export type AchAccountTypes = keyof typeof AchAccountTypes;
export const AchAccountTypes = {
  'Checking Account': 'Checking Account',
  'Savings Account': 'Savings Account',
} as const;

export type AchAccountHolderTypes = keyof typeof AchAccountHolderTypes;
export const AchAccountHolderTypes = {
  Personal: 'Personal',
  Business: 'Business',
  Unknown: 'Unknown',
} as const;

export type AchStandardEntryClassCodes = keyof typeof AchStandardEntryClassCodes;
export const AchStandardEntryClassCodes = {WEB: 'WEB', TEL: 'TEL', CCD: 'CCD', PPD: 'PPD'} as const;

export type PayStarAchStatuses = keyof typeof PayStarAchStatuses;
export const PayStarAchStatuses = {
  Pending: 'Pending',
  Voided: 'Voided',
  Submitted: 'Submitted',
  Settled: 'Settled',
  Reversed: 'Reversed',
  Returned: 'Returned',
  Error: 'Error',
} as const;

export type PayStarAchBatchStatuses = keyof typeof PayStarAchBatchStatuses;
export const PayStarAchBatchStatuses = {
  Open: 'Open',
  Closed: 'Closed',
  Submitted: 'Submitted',
  Settled: 'Settled',
} as const;

export type NachaFileSubmissionStatuses = keyof typeof NachaFileSubmissionStatuses;
export const NachaFileSubmissionStatuses = {
  Generated: 'Generated',
  Submitted: 'Submitted',
  Accepted: 'Accepted',
  Rejected: 'Rejected',
  Error: 'Error',
} as const;

export type ImportFileTypes = keyof typeof ImportFileTypes;
export const ImportFileTypes = {
  CSV: 'CSV',
  Excel: 'Excel',
  FixedWidth: 'FixedWidth',
  MultilineFixedWidth: 'MultilineFixedWidth',
  MultilineCSV: 'MultilineCSV',
} as const;

export type BusinessUnitFileTypes = keyof typeof BusinessUnitFileTypes;
export const BusinessUnitFileTypes = {
  'Client Provided Accounts': 'Client Provided Accounts',
  'Client Provided Bills': 'Client Provided Bills',
  'Client Provided Readings': 'Client Provided Readings',
  'Client Provided Outbound IVR File': 'Client Provided Outbound IVR File',
  'Automated Payment Report': 'Automated Payment Report',
  'Paperless Billing Report': 'Paperless Billing Report',
  'On Demand Account Report': 'On Demand Account Report',
  'On Demand Adjustments Report': 'On Demand Adjustments Report',
  'On Demand Autopay Activity Report': 'On Demand Autopay Activity Report',
  'On Demand Batch Report': 'On Demand Batch Report',
  'On Demand Paperless Billing Activity Report': 'On Demand Paperless Billing Activity Report',
  'On Demand Payment Report': 'On Demand Payment Report',
  'On Demand Products Report': 'On Demand Products Report',
  'FTP Upload File': 'FTP Upload File',
  'FTP Download File': 'FTP Download File',
  'Account Import Error Records': 'Account Import Error Records',
  'Job Artifact': 'Job Artifact',
  'NACHA File': 'NACHA File',
  'Gateway Batch File': 'Gateway Batch File',
  Other: 'Other',
} as const;

export type ListSeparators = keyof typeof ListSeparators;
export const ListSeparators = {',': ',', '|': '|', ';': ';', '\t': '\t'} as const;

export type ImportTransformers = keyof typeof ImportTransformers;
export const ImportTransformers = {
  Currency: 'Currency',
  JsTransform: 'JsTransform',
  Date: 'Date',
  Substring: 'Substring',
  StripNonAlphaNumeric: 'StripNonAlphaNumeric',
} as const;

export type AccountSyncLogTypes = keyof typeof AccountSyncLogTypes;
export const AccountSyncLogTypes = {
  'Account File Uploaded': 'Account File Uploaded',
  'Account File Staged': 'Account File Staged',
  'Account File Merged': 'Account File Merged',
  'Account File Processed': 'Account File Processed',
  'Account File Processing Error': 'Account File Processing Error',
} as const;

export type AccountSyncProperties = keyof typeof AccountSyncProperties;
export const AccountSyncProperties = {
  BusinessUnitId: 'BusinessUnitId',
  AccountNumber: 'AccountNumber',
  SubAccountNumber: 'SubAccountNumber',
  Balance: 'Balance',
  LateFee: 'LateFee',
  PastDueAmountMinor: 'PastDueAmountMinor',
  Name: 'Name',
  DueDate: 'DueDate',
  IssueDate: 'IssueDate',
  Note: 'Note',
  Meta: 'Meta',
  Address: 'Address',
  PhoneNumber: 'PhoneNumber',
  AllowOnlinePayment: 'AllowOnlinePayment',
  BlockCreditCardPayment: 'BlockCreditCardPayment',
  BlockAchPayment: 'BlockAchPayment',
  CreatedDate: 'CreatedDate',
  ModifiedDate: 'ModifiedDate',
  AccountSyncDate: 'AccountSyncDate',
  CreateMethod: 'CreateMethod',
  IsArchived: 'IsArchived',
  IsDeleted: 'IsDeleted',
} as const;

export type AccountSyncInsertProperties = keyof typeof AccountSyncInsertProperties;
export const AccountSyncInsertProperties = {Balance: 'Balance', Name: 'Name'} as const;

export type AccountSyncStrategies = keyof typeof AccountSyncStrategies;
export const AccountSyncStrategies = {
  Default: 'Default',
  FunctionApp: 'FunctionApp',
  ImpresaApi: 'ImpresaApi',
  IncodeApi: 'IncodeApi',
  RvsApi: 'RvsApi',
  UdsApi: 'UdsApi',
} as const;

export type MergeReplaceStrategies = keyof typeof MergeReplaceStrategies;
export const MergeReplaceStrategies = {Merge: 'Merge', Replace: 'Replace'} as const;

export type MissingAccountStrategies = keyof typeof MissingAccountStrategies;
export const MissingAccountStrategies = {
  None: 'None',
  Archive: 'Archive',
  'Set Balance to $0': 'Set Balance to $0',
} as const;

export type DuplicateAccountStrategies = keyof typeof DuplicateAccountStrategies;
export const DuplicateAccountStrategies = {
  'Apply Missing Strategy To All Occurrences': 'Apply Missing Strategy To All Occurrences',
  'Keep Last Occurrence Of Largest Balance': 'Keep Last Occurrence Of Largest Balance',
  'Keep Last Occurrence Of Most Recent Due Date': 'Keep Last Occurrence Of Most Recent Due Date',
  'Keep First Occurrence': 'Keep First Occurrence',
  'Keep Last Occurrence': 'Keep Last Occurrence',
} as const;

export type RequiredAccountSyncProperties = keyof typeof RequiredAccountSyncProperties;
export const RequiredAccountSyncProperties = {
  AccountNumber: 'AccountNumber',
  SubAccountNumber: 'SubAccountNumber',
  IsDeleted: 'IsDeleted',
  IsArchived: 'IsArchived',
  BusinessUnitId: 'BusinessUnitId',
  ModifiedDate: 'ModifiedDate',
  CreateMethod: 'CreateMethod',
  AccountSyncDate: 'AccountSyncDate',
} as const;

export type JobTaskTypes = keyof typeof JobTaskTypes;
export const JobTaskTypes = {
  TransformContext: 'TransformContext',
  GenerateTransactionExportV2: 'GenerateTransactionExportV2',
  GenerateTransactionExportFunction: 'GenerateTransactionExportFunction',
  GenerateAutopayEnrollmentExport: 'GenerateAutopayEnrollmentExport',
  GeneratePaperlessBillingEnrollmentExport: 'GeneratePaperlessBillingEnrollmentExport',
  CheckFtpForFile: 'CheckFtpForFile',
  UploadFileToFtp: 'UploadFileToFtp',
  SyncStagedAccounts: 'SyncStagedAccounts',
  ParseFile: 'ParseFile',
  ParseBills: 'ParseBills',
  LinkReadings: 'LinkReadings',
  InitiateFunctionOrchestration: 'InitiateFunctionOrchestration',
  ImportProcessedBills: 'ImportProcessedBills',
  StageImportedAccounts: 'StageImportedAccounts',
  TransformFile: 'TransformFile',
  SendEmail: 'SendEmail',
  SendWebhook: 'SendWebhook',
  Conditional: 'Conditional',
  GeneratePaymentLineItemReport: 'GeneratePaymentLineItemReport',
} as const;

export type JobTriggerTypes = keyof typeof JobTriggerTypes;
export const JobTriggerTypes = {
  'Account.FileUploaded': 'Account.FileUploaded',
  'Account.ImportErrorsFound': 'Account.ImportErrorsFound',
  'Account.ImportMerged': 'Account.ImportMerged',
  'Account.ImportStaged': 'Account.ImportStaged',
  'Account.PaymentProcessed': 'Account.PaymentProcessed',
  'AutoPay.Enrolled': 'AutoPay.Enrolled',
  'AutoPay.Unenrolled': 'AutoPay.Unenrolled',
  'Bill.CycleImportApproved': 'Bill.CycleImportApproved',
  'Bill.FileUploaded': 'Bill.FileUploaded',
  'BusinessUnit.SettingsUpdated': 'BusinessUnit.SettingsUpdated',
  'Forms.FormSubmitted': 'Forms.FormSubmitted',
  'Gateway.PartnerBatchClosed': 'Gateway.PartnerBatchClosed',
  'Gateway.PaystarAchBatchClosed': 'Gateway.PaystarAchBatchClosed',
  'Gateway.PaystarAchBatchCreated': 'Gateway.PaystarAchBatchCreated',
  'IVR.FileUploaded': 'IVR.FileUploaded',
  'OutboundIvr.Completed': 'OutboundIvr.Completed',
  'OutboundIvr.Failed': 'OutboundIvr.Failed',
  'PaperlessBilling.Enrolled': 'PaperlessBilling.Enrolled',
  'PaperlessBilling.Unenrolled': 'PaperlessBilling.Unenrolled',
  'Payment.AdjustmentFound': 'Payment.AdjustmentFound',
  'Payment.Declined': 'Payment.Declined',
  'Payment.Refunded': 'Payment.Refunded',
  'Payment.Succeeded': 'Payment.Succeeded',
  'Payment.Voided': 'Payment.Voided',
  'Readings.FileUploaded': 'Readings.FileUploaded',
  'Ticket.Created': 'Ticket.Created',
} as const;

export type BillTypes = keyof typeof BillTypes;
export const BillTypes = {
  Bill: 'Bill',
  Final: 'Final',
  Notice: 'Notice',
  'Late Notice': 'Late Notice',
  Recalculated: 'Recalculated',
  Invoice: 'Invoice',
} as const;

export type TaskExecutionStatuses = keyof typeof TaskExecutionStatuses;
export const TaskExecutionStatuses = {
  Pending: 'Pending',
  Skipped: 'Skipped',
  Processing: 'Processing',
  Successful: 'Successful',
  Failed: 'Failed',
} as const;

export type TaskExecutionRunConditions = keyof typeof TaskExecutionRunConditions;
export const TaskExecutionRunConditions = {
  'Previous Tasks Completed': 'Previous Tasks Completed',
  Always: 'Always',
  'Previous Tasks Exited Early': 'Previous Tasks Exited Early',
  'Previous Tasks Failed': 'Previous Tasks Failed',
  Never: 'Never',
} as const;

export type TaskExecutionActions = keyof typeof TaskExecutionActions;
export const TaskExecutionActions = {
  'Exit Early': 'Exit Early',
  Fail: 'Fail',
  Pass: 'Pass',
} as const;

export type ComparisonOperators = keyof typeof ComparisonOperators;
export const ComparisonOperators = {
  Contains: 'Contains',
  'Does Not Contain': 'Does Not Contain',
  'Does Not End With': 'Does Not End With',
  'Does Not Start With': 'Does Not Start With',
  'Ends With': 'Ends With',
  'Equal To': 'Equal To',
  'Greater Than': 'Greater Than',
  'Greater Than Or Equal To': 'Greater Than Or Equal To',
  'Is Not Set': 'Is Not Set',
  'Is Set': 'Is Set',
  'Less Than': 'Less Than',
  'Less Than Or Equal To': 'Less Than Or Equal To',
  'Not Equal To': 'Not Equal To',
  'Starts With': 'Starts With',
} as const;

export type ComparisonSetOperatorTypes = keyof typeof ComparisonSetOperatorTypes;
export const ComparisonSetOperatorTypes = {
  'All Match': 'All Match',
  'Any Match': 'Any Match',
} as const;

export type ConditionalTaskTypes = keyof typeof ConditionalTaskTypes;
export const ConditionalTaskTypes = {Standard: 'Standard', Complex: 'Complex'} as const;

export type BillingAddressCollectionTypes = keyof typeof BillingAddressCollectionTypes;
export const BillingAddressCollectionTypes = {
  None: 'None',
  'Full Address': 'Full Address',
  'Zip Only': 'Zip Only',
} as const;

export type CurrencyCodes = keyof typeof CurrencyCodes;
export const CurrencyCodes = {USD: 'USD', CAD: 'CAD'} as const;

export type ClientCultureTypes = keyof typeof ClientCultureTypes;
export const ClientCultureTypes = {'en-US': 'en-US', 'en-CA': 'en-CA'} as const;

export type ClientDbSettingTypes = keyof typeof ClientDbSettingTypes;
export const ClientDbSettingTypes = {
  'Autopay - Max Amount (Decimal)': 'Autopay - Max Amount (Decimal)',
  'Autopay - Days Processing Allowed Before Due Date':
    'Autopay - Days Processing Allowed Before Due Date',
  'Autopay - Days Scheduled Before Processed': 'Autopay - Days Scheduled Before Processed',
  'Autopay - Hours Cancellation Allowed Before Processed':
    'Autopay - Hours Cancellation Allowed Before Processed',
} as const;

export type DatePresets = keyof typeof DatePresets;
export const DatePresets = {
  Custom: 'Custom',
  Today: 'Today',
  Yesterday: 'Yesterday',
  'This Week': 'This Week',
  'Last Week': 'Last Week',
  'This Month': 'This Month',
  'Last Month': 'Last Month',
} as const;

export type FundingActivityTypes = keyof typeof FundingActivityTypes;
export const FundingActivityTypes = {
  'ACH Return': 'ACH Return',
  Sale: 'Sale',
  Refund: 'Refund',
} as const;

export type MessageTypes = keyof typeof MessageTypes;
export const MessageTypes = {
  Positive: 'Positive',
  Warning: 'Warning',
  Negative: 'Negative',
} as const;

export type MessagePaymentChannels = keyof typeof MessagePaymentChannels;
export const MessagePaymentChannels = {QuickPay: 'QuickPay', IVR: 'IVR'} as const;

export type OutboundSmsEnabledModes = keyof typeof OutboundSmsEnabledModes;
export const OutboundSmsEnabledModes = {Enabled: 'Enabled', Disabled: 'Disabled'} as const;

export type SignalRConfig = keyof typeof SignalRConfig;
export const SignalRConfig = {'/signalr-hub': '/signalr-hub'} as const;

export type SignalRTopics = keyof typeof SignalRTopics;
export const SignalRTopics = {
  'Global.AppUpdated': 'Global.AppUpdated',
  'Global.Notification': 'Global.Notification',
  'Global.MessagesUpdated': 'Global.MessagesUpdated',
  'Payment.Processed': 'Payment.Processed',
  'Payment.Voided': 'Payment.Voided',
  'Payment.Refunded': 'Payment.Refunded',
  'BusinessUnit.AccountsUpdated': 'BusinessUnit.AccountsUpdated',
  'BusinessUnit.BillsUpdated': 'BusinessUnit.BillsUpdated',
  'BusinessUnit.AccountAuditUpdated': 'BusinessUnit.AccountAuditUpdated',
  'BusinessUnit.OutboundIvrHistoryUpdated': 'BusinessUnit.OutboundIvrHistoryUpdated',
  'BusinessUnit.ReadingsAuditUpdated': 'BusinessUnit.ReadingsAuditUpdated',
  'Account.Updated': 'Account.Updated',
} as const;

export type TicketCategories = keyof typeof TicketCategories;
export const TicketCategories = {
  Manual: 'Manual',
  Client: 'Client',
  Technical: 'Technical',
} as const;

export type TicketEventTypes = keyof typeof TicketEventTypes;
export const TicketEventTypes = {
  Comment: 'Comment',
  'Ticket Modified': 'Ticket Modified',
  'Client Emailed': 'Client Emailed',
} as const;

export type TicketStatuses = keyof typeof TicketStatuses;
export const TicketStatuses = {
  New: 'New',
  Investigating: 'Investigating',
  'Waiting On Client': 'Waiting On Client',
  "Can't Reproduce": "Can't Reproduce",
  Resolved: 'Resolved',
  Closed: 'Closed',
} as const;

export type TicketAssignmentStatuses = keyof typeof TicketAssignmentStatuses;
export const TicketAssignmentStatuses = {Assigned: 'Assigned', Unassigned: 'Unassigned'} as const;

export type Timezones = keyof typeof Timezones;
export const Timezones = {
  'US/Pacific': 'US/Pacific',
  'US/Mountain': 'US/Mountain',
  'US/Central': 'US/Central',
  'US/Eastern': 'US/Eastern',
} as const;

export type ReportTypes = keyof typeof ReportTypes;
export const ReportTypes = {PDF: 'PDF', Excel: 'Excel', CSV: 'CSV'} as const;

export type UriProtocols = keyof typeof UriProtocols;
export const UriProtocols = {'http://': 'http://', 'https://': 'https://'} as const;

export type OutboundWebhookTypes = keyof typeof OutboundWebhookTypes;
export const OutboundWebhookTypes = {Generic: 'Generic', PaymentExport: 'PaymentExport'} as const;

export type OutboundWebhookStatuses = keyof typeof OutboundWebhookStatuses;
export const OutboundWebhookStatuses = {
  Queued: 'Queued',
  Accepted: 'Accepted',
  Failed: 'Failed',
} as const;

export type WebClientDirectories = keyof typeof WebClientDirectories;
export const WebClientDirectories = {portal: 'portal', customer: 'customer'} as const;

export type StandardTermsOfServiceIdentifiers = keyof typeof StandardTermsOfServiceIdentifiers;
export const StandardTermsOfServiceIdentifiers = {
  'ach-terms': 'ach-terms',
  'ach-terms-with-service-fee': 'ach-terms-with-service-fee',
  'autopay-enrollment': 'autopay-enrollment',
  'credit-card-terms': 'credit-card-terms',
  'credit-card-terms-with-service-fee': 'credit-card-terms-with-service-fee',
  'electronic-debit-authorization': 'electronic-debit-authorization',
  'terms-of-use': 'terms-of-use',
  'paperless-billing-enrollment': 'paperless-billing-enrollment',
  'accountless-terms': 'accountless-terms',
  'account-wallet-authorization': 'account-wallet-authorization',
  'account-wallet-terms': 'account-wallet-terms',
  'sms-notification-terms': 'sms-notification-terms',
  'back-office-autopay-enrollment': 'back-office-autopay-enrollment',
} as const;

export type AcknowledgmentTypes = keyof typeof AcknowledgmentTypes;
export const AcknowledgmentTypes = {
  'ACH Terms': 'ACH Terms',
  'AutoPay Enrollment': 'AutoPay Enrollment',
  'Credit Card Terms': 'Credit Card Terms',
  'Electronic Debit Authorization': 'Electronic Debit Authorization',
  'Terms Of Use': 'Terms Of Use',
  'Paperless Billing': 'Paperless Billing',
  'Account Wallet Terms': 'Account Wallet Terms',
  'Accountless Terms': 'Accountless Terms',
  'SMS Notification Terms': 'SMS Notification Terms',
  'Back Office Autopay Enrollment': 'Back Office Autopay Enrollment',
} as const;

export type FunctionTriggerTypes = keyof typeof FunctionTriggerTypes;
export const FunctionTriggerTypes = {
  Activity: 'Activity',
  SubOrchestration: 'SubOrchestration',
  Http: 'Http',
} as const;

export type FunctionApplications = keyof typeof FunctionApplications;
export const FunctionApplications = {
  Serverless: 'Serverless',
  Bills: 'Bills',
  Engage: 'Engage',
} as const;

export type ExternalLinkDisplayLocations = keyof typeof ExternalLinkDisplayLocations;
export const ExternalLinkDisplayLocations = {'Customer Dashboard': 'Customer Dashboard'} as const;

export type FormDisplayLocations = keyof typeof FormDisplayLocations;
export const FormDisplayLocations = {
  'Customer Dashboard': 'Customer Dashboard',
  Login: 'Login',
  Checkout: 'Checkout',
} as const;

export type PaymentActivityReportStrategy = keyof typeof PaymentActivityReportStrategy;
export const PaymentActivityReportStrategy = {
  JsReport: 'JsReport',
  ServerlessExport: 'ServerlessExport',
} as const;

export type NachaEncryptionTypes = keyof typeof NachaEncryptionTypes;
export const NachaEncryptionTypes = {
  None: 'None',
  Encrypt: 'Encrypt',
  'Encrypt and Sign': 'Encrypt and Sign',
} as const;

export type FormResponseStatuses = keyof typeof FormResponseStatuses;
export const FormResponseStatuses = {
  Errored: 'Errored',
  Submitting: 'Submitting',
  Submitted: 'Submitted',
} as const;

export type OnboardingSessionStatuses = keyof typeof OnboardingSessionStatuses;
export const OnboardingSessionStatuses = {
  VerifyCustomer: 'VerifyCustomer',
  VerifyAccount: 'VerifyAccount',
  SelectPaperlessBillingEnrollment: 'SelectPaperlessBillingEnrollment',
  SelectAutopayEnrollment: 'SelectAutopayEnrollment',
  SelectNotificationsEnrollment: 'SelectNotificationsEnrollment',
  VerifyPhoneNumber: 'VerifyPhoneNumber',
  Redirect: 'Redirect',
  Invalid: 'Invalid',
  Cancelled: 'Cancelled',
  Completed: 'Completed',
} as const;

export type PayerLoginModes = keyof typeof PayerLoginModes;
export const PayerLoginModes = {Paystar: 'Paystar', Embedded: 'Embedded'} as const;

export type OutboundIvrJobStatuses = keyof typeof OutboundIvrJobStatuses;
export const OutboundIvrJobStatuses = {
  Completed: 'Completed',
  Pending: 'Pending',
  Failed: 'Failed',
  Error: 'Error',
} as const;

export type JobExecutionStatuses = keyof typeof JobExecutionStatuses;
export const JobExecutionStatuses = {
  Processing: 'Processing',
  Paused: 'Paused',
  Successful: 'Successful',
  ExitedEarly: 'ExitedEarly',
  Failed: 'Failed',
} as const;

export type CheckFtpForFile_Variables = keyof typeof CheckFtpForFile_Variables;
export const CheckFtpForFile_Variables = {
  '{{CheckFtpForFile_BlobUrl}}': '{{CheckFtpForFile_BlobUrl}}',
  '{{CheckFtpForFile_DownloadUrl}}': '{{CheckFtpForFile_DownloadUrl}}',
  '{{CheckFtpForFile_DownloadUrls}}': '{{CheckFtpForFile_DownloadUrls}}',
} as const;

export type GenerateAutopayEnrollmentExport_Variables =
  keyof typeof GenerateAutopayEnrollmentExport_Variables;
export const GenerateAutopayEnrollmentExport_Variables = {
  '{{GenerateAutopayEnrollmentExport_TotalActivityCount}}':
    '{{GenerateAutopayEnrollmentExport_TotalActivityCount}}',
  '{{GenerateAutopayEnrollmentExport_EnrollCount}}':
    '{{GenerateAutopayEnrollmentExport_EnrollCount}}',
  '{{GenerateAutopayEnrollmentExport_UnEnrollCount}}':
    '{{GenerateAutopayEnrollmentExport_UnEnrollCount}}',
  '{{GenerateAutopayEnrollmentExport_UpdateCount}}':
    '{{GenerateAutopayEnrollmentExport_UpdateCount}}',
  '{{GenerateAutopayEnrollmentExport_BlobUrl}}': '{{GenerateAutopayEnrollmentExport_BlobUrl}}',
  '{{GenerateAutopayEnrollmentExport_DownloadUrl}}':
    '{{GenerateAutopayEnrollmentExport_DownloadUrl}}',
  '{{GenerateAutopayEnrollmentExport_FileName}}': '{{GenerateAutopayEnrollmentExport_FileName}}',
} as const;

export type GeneratePaperlessBillingEnrollmentExport_Variables =
  keyof typeof GeneratePaperlessBillingEnrollmentExport_Variables;
export const GeneratePaperlessBillingEnrollmentExport_Variables = {
  '{{GeneratePaperlessBillingEnrollmentExport_BlobUrl}}':
    '{{GeneratePaperlessBillingEnrollmentExport_BlobUrl}}',
  '{{GeneratePaperlessBillingEnrollmentExport_DownloadUrl}}':
    '{{GeneratePaperlessBillingEnrollmentExport_DownloadUrl}}',
  '{{GeneratePaperlessBillingEnrollmentExport_FileName}}':
    '{{GeneratePaperlessBillingEnrollmentExport_FileName}}',
} as const;

export type GeneratePaymentLineItemReport_Variables =
  keyof typeof GeneratePaymentLineItemReport_Variables;
export const GeneratePaymentLineItemReport_Variables = {
  '{{GeneratePaymentLineItemReport_BlobUrl}}': '{{GeneratePaymentLineItemReport_BlobUrl}}',
  '{{GeneratePaymentLineItemReport_DownloadUrl}}': '{{GeneratePaymentLineItemReport_DownloadUrl}}',
  '{{GeneratePaymentLineItemReport_LineItemCount}}':
    '{{GeneratePaymentLineItemReport_LineItemCount}}',
  '{{GeneratePaymentLineItemReport_FileName}}': '{{GeneratePaymentLineItemReport_FileName}}',
} as const;

export type GeneratePaymentLineItemReport_PaymentLineItemTypes =
  keyof typeof GeneratePaymentLineItemReport_PaymentLineItemTypes;
export const GeneratePaymentLineItemReport_PaymentLineItemTypes = {
  AccountBalance: 'AccountBalance',
  OpenCharge: 'OpenCharge',
  LateFee: 'LateFee',
  Product: 'Product',
  ServiceFee: 'ServiceFee',
} as const;

export type GenerateTransactionExportFunction_Variables =
  keyof typeof GenerateTransactionExportFunction_Variables;
export const GenerateTransactionExportFunction_Variables = {
  '{{GenerateTransactionExportFunction_BlobUrl}}': '{{GenerateTransactionExportFunction_BlobUrl}}',
  '{{GenerateTransactionExportFunction_DownloadUrl}}':
    '{{GenerateTransactionExportFunction_DownloadUrl}}',
} as const;

export type GenerateTransactionExportV2_Variables =
  keyof typeof GenerateTransactionExportV2_Variables;
export const GenerateTransactionExportV2_Variables = {
  '{{GenerateTransactionExportV2_BlobUrl}}': '{{GenerateTransactionExportV2_BlobUrl}}',
  '{{GenerateTransactionExportV2_DownloadUrl}}': '{{GenerateTransactionExportV2_DownloadUrl}}',
  '{{GenerateTransactionExportV2_TransactionCount}}':
    '{{GenerateTransactionExportV2_TransactionCount}}',
  '{{GenerateTransactionExportV2_FileName}}': '{{GenerateTransactionExportV2_FileName}}',
} as const;

export type ImportProcessedBills_Variables = keyof typeof ImportProcessedBills_Variables;
export const ImportProcessedBills_Variables = {
  '{{ImportProcessedBills_BillCount}}': '{{ImportProcessedBills_BillCount}}',
} as const;

export type InitiateFunctionOrchestration_Variables =
  keyof typeof InitiateFunctionOrchestration_Variables;
export const InitiateFunctionOrchestration_Variables = {
  '{{InitiateFunctionOrchestration_ResumeExecutionUrl}}':
    '{{InitiateFunctionOrchestration_ResumeExecutionUrl}}',
  '{{InitiateFunctionOrchestration_FailExecutionUrl}}':
    '{{InitiateFunctionOrchestration_FailExecutionUrl}}',
} as const;

export type LinkReadings_Variables = keyof typeof LinkReadings_Variables;
export const LinkReadings_Variables = {} as const;

export type ParseBills_Variables = keyof typeof ParseBills_Variables;
export const ParseBills_Variables = {
  '{{ParseBills_BillCount}}': '{{ParseBills_BillCount}}',
} as const;

export type ParseFile_Variables = keyof typeof ParseFile_Variables;
export const ParseFile_Variables = {
  '{{ParseFile_SuccessCount}}': '{{ParseFile_SuccessCount}}',
  '{{ParseFile_SkippedCount}}': '{{ParseFile_SkippedCount}}',
} as const;

export type SendEmail_Variables = keyof typeof SendEmail_Variables;
export const SendEmail_Variables = {
  '{{SendEmail_SomeStatus}}': '{{SendEmail_SomeStatus}}',
} as const;

export type SendWebhook_Variables = keyof typeof SendWebhook_Variables;
export const SendWebhook_Variables = {} as const;

export type StageImportedAccounts_Variables = keyof typeof StageImportedAccounts_Variables;
export const StageImportedAccounts_Variables = {
  '{{StageImportedAccounts_ImportSource}}': '{{StageImportedAccounts_ImportSource}}',
  '{{StageImportedAccounts_TotalStagedAccounts}}': '{{StageImportedAccounts_TotalStagedAccounts}}',
  '{{StageImportedAccounts_TotalSkippedAccounts}}':
    '{{StageImportedAccounts_TotalSkippedAccounts}}',
} as const;

export type SyncStagedAccounts_Variables = keyof typeof SyncStagedAccounts_Variables;
export const SyncStagedAccounts_Variables = {
  '{{SyncStagedAccounts_TotalStagedAccounts}}': '{{SyncStagedAccounts_TotalStagedAccounts}}',
  '{{SyncStagedAccounts_TotalExistingAccounts}}': '{{SyncStagedAccounts_TotalExistingAccounts}}',
  '{{SyncStagedAccounts_StagedNewAccounts}}': '{{SyncStagedAccounts_StagedNewAccounts}}',
  '{{SyncStagedAccounts_StagedMatchedAccounts}}': '{{SyncStagedAccounts_StagedMatchedAccounts}}',
  '{{SyncStagedAccounts_ExistingAccountsNotStaged}}':
    '{{SyncStagedAccounts_ExistingAccountsNotStaged}}',
  '{{SyncStagedAccounts_AggregateTotal}}': '{{SyncStagedAccounts_AggregateTotal}}',
} as const;

export type TransformContext_Variables = keyof typeof TransformContext_Variables;
export const TransformContext_Variables = {
  '{{TransformContext_NewContextValues}}': '{{TransformContext_NewContextValues}}',
} as const;

export type TransformFile_Variables = keyof typeof TransformFile_Variables;
export const TransformFile_Variables = {
  '{{Transform_Records}}': '{{Transform_Records}}',
  '{{TransformFile_BlobUrl}}': '{{TransformFile_BlobUrl}}',
  '{{TransformFile_DownloadUrl}}': '{{TransformFile_DownloadUrl}}',
} as const;

export type UploadFileToFtp_Variables = keyof typeof UploadFileToFtp_Variables;
export const UploadFileToFtp_Variables = {
  '{{UploadFileToFtp_BlobUrl}}': '{{UploadFileToFtp_BlobUrl}}',
} as const;

export type JobExecution_Variables = keyof typeof JobExecution_Variables;
export const JobExecution_Variables = {
  '{{JobExecution_Identifier}}': '{{JobExecution_Identifier}}',
  '{{JobExecution_Id}}': '{{JobExecution_Id}}',
  '{{JobExecution_JobId}}': '{{JobExecution_JobId}}',
  '{{JobExecution_BusinessUnitId}}': '{{JobExecution_BusinessUnitId}}',
  '{{JobExecution_BusinessUnitSlug}}': '{{JobExecution_BusinessUnitSlug}}',
  '{{JobExecution_BusinessUnitHeader}}': '{{JobExecution_BusinessUnitHeader}}',
  '{{JobExecution_BusinessUnitSubHeader}}': '{{JobExecution_BusinessUnitSubHeader}}',
  '{{JobExecution_BusinessUnitAutopayEnabled}}': '{{JobExecution_BusinessUnitAutopayEnabled}}',
  '{{JobExecution_BusinessUnitTimezone}}': '{{JobExecution_BusinessUnitTimezone}}',
  '{{JobExecution_StartTime}}': '{{JobExecution_StartTime}}',
  '{{JobExecution_TimeZone}}': '{{JobExecution_TimeZone}}',
} as const;

// Statics

export const RoleTemplates = {
  'Global Admin': [
    'Accounts:Manage',
    'Accounts:Read',
    'Billing:Default',
    'BusinessUnits:Manage',
    'BusinessUnits:Read',
    'Payments:Default',
    'Payments:Refund',
    'Payments:Void',
    'Reports:Default',
    'Transactions:Default',
    'Users:Manage',
    'Users:Read',
    'AutopayEnrollment:Manage',
    'ScheduledPayments:Manage',
  ],
  'Organization Admin': [
    'Accounts:Manage',
    'Accounts:Read',
    'Billing:Default',
    'BusinessUnits:Manage',
    'BusinessUnits:Read',
    'Payments:Default',
    'Payments:Refund',
    'Payments:Void',
    'Reports:Default',
    'Transactions:Default',
    'Users:Manage',
    'Users:Read',
    'AutopayEnrollment:Manage',
    'ScheduledPayments:Manage',
  ],
  'Organization User': [
    'Accounts:Manage',
    'Accounts:Read',
    'Billing:Default',
    'BusinessUnits:Read',
    'Payments:Default',
    'Reports:Default',
    'Transactions:Default',
  ],
  Customer: [],
  QuickPay: [],
  'Base User': [],
} as const;

export const AllPermissions = [
  'Accounts:Manage',
  'Accounts:Read',
  'Billing:Default',
  'BusinessUnits:Manage',
  'BusinessUnits:Read',
  'Payments:Default',
  'Payments:Refund',
  'Payments:Void',
  'Reports:Default',
  'Transactions:Default',
  'Users:Manage',
  'Users:Read',
  'AutopayEnrollment:Manage',
  'ScheduledPayments:Manage',
] as const;

export const DefaultAccountFields = [
  {
    BusinessUnitId: 0,
    Type: 'AccountNumber',
    Label: 'Account Number',
    Description: null,
    IsQuickPayLookupField: true,
    IsIvrLookupField: true,
    IsDisplayField: true,
    IsRequired: true,
    Order: 0,
  },
  {
    BusinessUnitId: 0,
    Type: 'SubAccountNumber',
    Label: 'Subaccount Number',
    Description: null,
    IsQuickPayLookupField: false,
    IsIvrLookupField: false,
    IsDisplayField: false,
    IsRequired: false,
    Order: 1,
  },
  {
    BusinessUnitId: 0,
    Type: 'Name',
    Label: 'Name',
    Description: null,
    IsQuickPayLookupField: false,
    IsIvrLookupField: false,
    IsDisplayField: true,
    IsRequired: false,
    Order: 2,
  },
  {
    BusinessUnitId: 0,
    Type: 'Balance',
    Label: 'Balance',
    Description: null,
    IsQuickPayLookupField: false,
    IsIvrLookupField: false,
    IsDisplayField: true,
    IsRequired: false,
    Order: 3,
  },
  {
    BusinessUnitId: 0,
    Type: 'IssueDate',
    Label: 'Issue Date',
    Description: null,
    IsQuickPayLookupField: false,
    IsIvrLookupField: false,
    IsDisplayField: true,
    IsRequired: false,
    Order: 4,
  },
  {
    BusinessUnitId: 0,
    Type: 'DueDate',
    Label: 'Due Date',
    Description: null,
    IsQuickPayLookupField: false,
    IsIvrLookupField: false,
    IsDisplayField: true,
    IsRequired: false,
    Order: 5,
  },
  {
    BusinessUnitId: 0,
    Type: 'Zip',
    Label: 'Zip Code',
    Description: null,
    IsQuickPayLookupField: false,
    IsIvrLookupField: false,
    IsDisplayField: false,
    IsRequired: false,
    Order: 6,
  },
  {
    BusinessUnitId: 0,
    Type: 'Address',
    Label: 'Address',
    Description: null,
    IsQuickPayLookupField: false,
    IsIvrLookupField: false,
    IsDisplayField: true,
    IsRequired: false,
    Order: 7,
  },
  {
    BusinessUnitId: 0,
    Type: 'PhoneNumber',
    Label: 'Phone Number',
    Description: null,
    IsQuickPayLookupField: false,
    IsIvrLookupField: false,
    IsDisplayField: true,
    IsRequired: false,
    Order: 8,
  },
  {
    BusinessUnitId: 0,
    Type: 'Note',
    Label: 'Note',
    Description: null,
    IsQuickPayLookupField: false,
    IsIvrLookupField: false,
    IsDisplayField: true,
    IsRequired: false,
    Order: 9,
  },
  {
    BusinessUnitId: 0,
    Type: 'LateFee',
    Label: 'Late Fee',
    Description: null,
    IsQuickPayLookupField: false,
    IsIvrLookupField: false,
    IsDisplayField: true,
    IsRequired: false,
    Order: 10,
  },
  {
    BusinessUnitId: 0,
    Type: 'Description',
    Label: 'Description',
    Description: null,
    IsQuickPayLookupField: false,
    IsIvrLookupField: false,
    IsDisplayField: false,
    IsRequired: false,
    Order: 11,
  },
] as const;
